<template>
  <v-container fluid>
    <v-row class="mt-0">
      <v-col>
        <btn-voltar to="/grupos" />
      </v-col>
    </v-row>

    <grupos-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-buscar="carregandoBuscar"
      :validacao-formulario="validacaoFormulario"
      :grupo="grupo"
      :permissoes="permissoes"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import grupos from '@/api/grupos'

  export default {

    components: {
      GruposCampos: () => import('./GruposCampos'),
    },

    data () {
      return {
        carregandoSalvar: false,
        carregandoBuscar: false,
        validacaoFormulario: {},
        grupo: {},
        permissoes: [],
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregandoBuscar = true

          const resposta = await grupos.buscar(this.$route.params.id)
          const respostaPermissoes = await grupos.buscarPermissoes(this.$route.params.id)

          this.grupo = resposta.data
          this.permissoes = respostaPermissoes.data
        } catch (e) {
          this.$router.push('/grupos', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoBuscar = false
        }
      },

      async salvar (grupo, permissoes) {
        this.carregandoSalvar = true

        try {
          await grupos.atualizar(grupo.id, grupo)
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = e.response.data.errors
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
          return
        } finally {
          this.carregandoSalvar = false
        }

        try {
          this.carregandoSalvar = true

          await grupos.atualizarPermissoes(grupo.id, {
            permissoes: permissoes.join(','),
          })

          this.$router.push('/grupos', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_atualizar') }))
          return
        } catch (e) {
          this.$router.push('/grupos', () => this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Grupo atualizado, mas as permissões não foram salvas, edite e confira' }))
          return
        } finally {
          this.carregandoSalvar = false
        }
      },
    },

  }
</script>
