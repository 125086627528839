import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`grupos?${parametros}`)
  },

  async buscar (id) {
    return await axios.get(`grupos/${id}`)
  },

  async inserir (dados) {
    return await axios.post('grupos', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`grupos/${id}`, dados)
  },

  async buscarPermissoes (id) {
    return await axios.get(`grupos/${id}/permissoes`)
  },

  async atualizarPermissoes (id, dados) {
    return await axios.put(`grupos/${id}/permissoes`, dados)
  },

  async deletar (id) {
    return await axios.delete(`grupos/${id}`)
  },

}
